<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :footer-actions="footerActions"
      :sums="sums"
      :is-page-show="true"
      api-url="/goods/v1/uid-failed-list"
      api-download-url="/goods/v1/uid-failed-export"
      download-file-name="uid-failed.xlsx"
      @showDialogHistory="showDialogHistory"
    ></custom-table>
  </div>
</template>

<script>
import {
  IMAGE_OPTIONS,
  YES_NO_OPTIONS,
  GOODS_UID_STATUS_OPTIONS,
  EXPORT_DEFECTIVE_OPTIONS,
} from "@/libs/const";
export default {
  name: "UidFailed",
  components: {},
  data: () => ({
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    historyDialog: false,
    rollDialog: false,
    itemDetail: {},
    statusOptions: [...GOODS_UID_STATUS_OPTIONS],
    yesNoStatus: [...YES_NO_OPTIONS],
    imageOptions: [...IMAGE_OPTIONS],
    exportOptions: [...EXPORT_DEFECTIVE_OPTIONS],
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    const columns = [
      {
        type: "date-range-filter",
        label: this.$t("labels.qc"),
        placeholder: this.$t("labels.qc"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.basket_code"),
        placeholder: this.$t("labels.basket_code"),
        name: "basket_code",
        hasSort: false,
        sortName: "basket_code",
        key: "basket_code",
        required: true,
      },
      {
        type: "select-employee",
        label: this.$t("labels.employee"),
        placeholder: this.$t("labels.employee"),
        name: "id_employee",
        hasSort: false,
        sortName: "employee_name",
        key: "employee_name",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.image"),
        placeholder: this.$t("labels.image"),
        name: "image",
        hasSort: false,
        sortName: "url_images",
        key: "url_images",
        options: this.imageOptions,
        required: true,
        component: "Image",
      },
      {
        type: "input-filter",
        label: this.$t("labels.barcode"),
        placeholder: this.$t("labels.barcode"),
        name: "customer_goods_barcode",
        hasSort: false,
        sortName: "customer_goods_barcode",
        key: "customer_goods_barcode",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.sku"),
        placeholder: this.$t("labels.sku"),
        name: "sku",
        hasSort: false,
        sortName: "sku",
        key: "sku",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.code_imei"),
        placeholder: this.$t("labels.code_imei"),
        name: "keywords",
        hasSort: false,
        sortName: "sku",
        key: "sku_uid",
        required: true,
        /* actionPermissions: [],
        actionModules: [],
        action: "showDialogHistory", */
      },
      {
        type: "input-filter",
        label: this.$t("labels.name"),
        placeholder: this.$t("labels.name"),
        name: "name",
        hasSort: false,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.goods_description"),
        placeholder: this.$t("labels.goods_description"),
        name: "description",
        hasSort: false,
        sortName: "description",
        key: "description",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.size"),
        placeholder: this.$t("labels.size"),
        name: "size",
        hasSort: false,
        sortName: "size",
        key: "size",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.export_status"),
        placeholder: this.$t("labels.export_status"),
        name: "is_export",
        hasSort: false,
        sortName: "is_export",
        key: "is_export",
        options: this.exportOptions,
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
    showDialogHistory(item) {
      this.itemDetail = { ...item };
      this.historyDialog = true;
    },
    hideDialogHistory() {
      this.itemDetail = {};
      this.historyDialog = false;
    },
  },
};
</script>

<style scoped></style>
